import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';


import styles from './SkillsComponent.module.scss'

import htmlLogo from '../../assets/images/logos/html-logo.png';
import cssLogo from '../../assets/images/logos/css-logo.png';
import sassLogo from '../../assets/images/logos/sass-logo.png';
import jsLogo from '../../assets/images/logos/js-logo.png';
import reactLogo from '../../assets/images/logos/react-logo.png';
import wpLogo from '../../assets/images/logos/wp-logo.png';
import gitLogo from '../../assets/images/logos/php-1-logo-png-transparent.png';
import bootstrapLogo from '../../assets/images/logos/bootstrap-logo.png';



export default function SkillsComponent(props) {

    const [inProp, setInProp] = useState(false);

    const transitionStyles = {
        entering: { filter: `grayscale(1)` },
        entered: { filter: `grayscale(0)` },
        exiting: { filter: `grayscale(0)` },
        exited: { filter: `grayscale(1)` },
    };

    const transitionStylesText = {
        entering: { transform: `translateX(10%)`, opacity:`0` },
        entered: { transform: `translateX(0)`, opacity: `1` },
        exiting: { transform: `translateX(0)`, opacity: `1` },
        exited: { transform: `translateX(10%)`, opacity: `0` },
    };

    const transitionStylesText2 = {
        entering: { transform: `translateX(-10%)`, opacity:`0` },
        entered: { transform: `translateX(0)`, opacity: `1` },
        exiting: { transform: `translateX(0)`, opacity: `1` },
        exited: { transform: `translateX(-10%)`, opacity: `0` },
    };

    useEffect(() => {
        setInProp(true)
    }, [])



    const logos = [htmlLogo, cssLogo, bootstrapLogo, sassLogo]
    const logos2 = [jsLogo, reactLogo, wpLogo, gitLogo]
    const names = [ 'HTML', 'CSS', 'BOOTSTRAP', 'SASS' ]
    const names2 = [ 'JAVASCRIPT', 'REACT', 'WORDPRESS', 'PHP' ]

    const logosWrapper = (arr, delayT) => {
    return  (
        <div className={styles.logosWrapper}>
            {
            arr.map((m, i) => {
                const duration = 100;
                const delay = (i+delayT) * 200;

                return (
                    <Transition in={inProp} key={i} timeout={ duration  }>
                        {state => (
                            <img className={styles.logo}
                                src={m}
                                alt="logo"
                                style={{
                                    transition: `all ${duration}ms ease ${delay}ms`,
                                    ...transitionStyles[state]
                                }}
                            />
                        )}
                    </Transition>
                )
            })}
        </div>)}




    const technologies = (arr, delayT, transitions) => <div className={styles.namesWrapper}>
                                {arr.map( (n, i) => {
                                    const duration = 100;
                                    const delay = (i+delayT) * 200;

                                    return (
                                        <Transition in={inProp} key={i} timeout={duration}>
                                            {state => (
                                                <p
                                                    style={{
                                                    transition: `all ${duration}ms ease-in-out ${delay}ms`,
                                                    ...transitions[state]
                                                }}>
                                                    {n}
                                                </p>
                                            )}
                                        </Transition>
                                    )
                                } )}
                            </div>



    return (

        <div className={styles.skillsCompContainer}>
            <div className={styles.partWrapper}>

                {technologies(names, 5, transitionStylesText )}
                {logosWrapper( logos, 5 )}

            </div>
            <div className={styles.partWrapper}>

                {logosWrapper(logos2, 9)}
                {technologies(names2, 9, transitionStylesText2 )}

            </div>
        </div>

    )


}
