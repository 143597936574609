export const levelObjectActive = [
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("P     R     O      J      E      KTY"),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 22,
        changed: [2, 4, 6],
    },
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("P     R     O      J      E         "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 32,
        changed: [2, 4, 6],
    },
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("P     R     O                       "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 42,
        changed: [2, 4, 6],
    }
]


export const levelObject = [
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("      P     R     O     J      EKTY "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 75,
        changed: [2, 4, 6],
    },
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("      P     R     O     J      E    "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 83,
        changed: [2, 4, 6],
    },
    {
        cols: 6,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("      P     R     O                 "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 88,
        changed: [2, 4, 6],
    }
]
