export const levelObjectActive = [
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from(" O M   N   E"),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 50,
        changed: [2, 4, 6],
    },
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from(" O M   N    "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 55,
        changed: [2, 4, 6],
    },
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("   M        "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 60,
        changed: [2, 4, 6],
    }
]


export const levelObject = [
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from(" O MNE      "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 45,
        changed: [2, 4, 6],
    },
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from(" O MN       "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 48,
        changed: [2, 4, 6],
    },
    {
        cols: 3,
        rows: 4,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("   M        "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 51,
        changed: [2, 4, 6],
    }
]
