import React, { useState } from "react";
import axios from "axios";

import styles from './ContactForm.module.scss';

import { BsCheck } from 'react-icons/bs';




const Form = ({ focusContactForm }) => {

    const [status, setStatus] = useState("Poslať");
    const [insertedName, setInsertedName] = useState(false);
    const [insertedEmail, setInsertedEmail] = useState(false);
    const [insertedMessage, setInsertedMessage] = useState(false);
    const [checked, setChecked] = useState(false);
    const [readyButton, setReadyButton] = useState(false);
    const [formDatas, setformDatas] = useState({
        name: '',
        email: '',
        message: '',
        terms: false});
    const [messageSent, setMessageSent] = useState(false);
    //const focusContactForm = (e) => props.focusContactForm;


    //const API_PATH = 'http://localhost/mypage3.0/src/components/ContactComponent/index.php';

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!checked) {
            return;
        }

        setStatus("Posiela sa...");
        const { name, email, message, terms } = e.target.elements;

        let details = {
            name: name.value,
            email: email.value,
            message: message.value,
            terms: terms.value
        };

        axios({
            method: 'post',
            url: '/sendEmail.php',
            headers: { "Content-Type": "application/json" },
            data: details
        })
            .then(result => {
                if (result.status === 200) {
                    setMessageSent(true);
                    setformDatas({...formDatas,
                        name: '',
                        email: '',
                        message: '',
                        terms: false
                    })
                    allInserted();
                    setInsertedName(false);
                    setInsertedEmail(false);
                    setInsertedMessage(false);
                    setReadyButton(false);
                } else {
                    setMessageSent(false);
                }

            })
            .catch(error => {
                console.log(error);
            });

        setStatus("Poslať");
    }


    const allInserted = () => {
        const { name, email, message, terms } = formDatas;

        if (name && email && message && terms) {
            setReadyButton(true);
        } else setReadyButton(false);
    }

    const handleChangeForm = (e) => {
        let item = e.target.name;
        formDatas[item] = e.target.name === "terms" ? e.target.checked : e.target.value;;
        setformDatas({ ...formDatas })
        setMessageSent(false);

        let emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        switch (e.target.name) {
            case "name":
                formDatas.name.length > 1 ? setInsertedName(true) : setInsertedName(false);
                allInserted();
                break;
            case "email":
                emailTest.test(e.target.value) ? setInsertedEmail(true) : setInsertedEmail(false);
                allInserted();
                break;
            case "message":
                formDatas.message.length > 0 ? setInsertedMessage(true) : setInsertedMessage(false);
                allInserted();
                break;
            case "terms":
                formDatas.terms ? setChecked(true) : setChecked(false);
                allInserted();
                break;
            default:
                break;
        }
    }


    const handleFocusMobile = (e) => {
        focusContactForm(e);
    }


    const handleBlurMobile = (e) => {
        focusContactForm(e)
    }




    return (


            <div className="container">
                <form onSubmit={handleSubmit} noValidate>
                    <div className={styles.inputWrapper}>
                        <input type="text" name="name" required
                                placeholder="Meno"
                                value={formDatas.name}
                                onChange={handleChangeForm}
                                onFocus={(e) => focusContactForm(e)}
                                onBlur={(e) => handleBlurMobile(e)}/>
                        {insertedName ? <BsCheck fill="#519c22" className={styles.checkIcon} size="58" /> : null}
                    </div>

                    <div className={styles.inputWrapper}>
                        <input type="email" name="email" required
                                placeholder="Email"
                                value={formDatas.email}
                                onChange={handleChangeForm}
                                onFocus={(e) => handleFocusMobile(e)}
                                onBlur={(e) => handleBlurMobile(e)}/>
                        {insertedEmail ? <BsCheck fill="#519c22" className={styles.checkIcon} size="58" /> : null}
                    </div>

                    <div className={styles.inputWrapper}>
                        <textarea name="message" required placeholder="Správa"
                                value={formDatas.message}
                                onChange={handleChangeForm}
                                onFocus={(e) => handleFocusMobile(e)}
                                onBlur={(e) => handleBlurMobile(e)}/>
                        {insertedMessage ? <BsCheck fill="#519c22" className={styles.checkIcon} size="58" /> : null}
                    </div>

                    <div className={styles.acceptWrapper}>
                        <div className={styles.wrapper}>
                            <label className={styles.acceptLabel}>

                                <input className={styles.checkbox}
                                        type="checkbox" name="terms"
                                        value={formDatas.terms}
                                        checked={formDatas.terms}
                                        onChange={handleChangeForm}/>

                                <span className={styles.checkboxSpan}>Súhlasím so spracovaním &nbsp; <p>osobných údajov</p></span>
                            </label>
                        </div>
                    </div>


                    <div className={styles.sendEmailWrapper}>
                            <button type="submit" className={readyButton ? styles.readyButton : ""} >{status}</button>

                            {messageSent ? <p className={styles.messageSent}>Správa bola odoslaná</p> : null}

                    </div>
                </form>
            </div>
    );
}

export default Form;
