
/**
 *Function for displaying images in specific coordinates and colors
 * @param {*propsObject = {
            cols: number,               // coordinateTable function,
            rows: number,               // coordinateTable function,
            colWidth: number,           // coordinateTable function,
            rowHeight: number,          // coordinateTable function,
            shift: number,              // coordinateTable function,
            letters: string[]           // string of letters for display
            colorClass: string,         // css class changing color for chosen letters
            selectedLetters: bool,      // choose whether select another color to selected Letter
            delay: number,              // delay in seconds of displaying letters
            changed: number[],          // chosen letters with other color than default
            }}
 * @param {* setInterval } interval
 * @param {* general styles for all App} genStyles
 * @param {* local styles of component} styles
 * @param {* image to display} image
 * @returns group of elements(images) displayed in specific coordinates
 */

export function level(propsObject, interval, genStyles, styles, image, refCollection) {

    let { cols, rows, colWidth, rowHeight, shift, letters, colorClass, selectedLetters, delay, changed } = propsObject
    let coordinatesArray = coordinatesTable(cols, rows, colWidth, rowHeight, shift);
    let lettersArr = Array.from(letters);
    let items = -1


    /**
     * Function for counting coordinates
     * @param {*number of columns} cols
     * @param {*number of rows} rows
     * @param {*width of column(px)} colWidth
     * @param {*height of row(px)} rowHeight
     * @param {*diagonal shift of table} shift
     * @returns Array off coordinates( [[a,a],[a,b,],[b,a],[b,b]] )
     */

    function coordinatesTable(cols, rows, colWidth, rowHeight, shift) {

        let coordinatesArray = new Array(0);

        for (let r = 0; r < rows; r++) {
            for (let i = 0; i < cols; i++) {
                coordinatesArray.push([i * colWidth + shift, r * rowHeight + shift])
            }
        }
        return coordinatesArray;

    }

    /**
     * Function for adding a another color after clicking on element
     * @param {* clicked element } data
     * @param {* local styles of component} styles
     */

    function handleResultClick(data) {
        const brickWrapper = data.target.parentElement;

        brickWrapper.classList.toggle(styles.resultBlick);
        brickWrapper.addEventListener('animationend', () => {
            brickWrapper.classList.toggle(styles.resultBlick);
        });
    }


    /**
     * Function for removing elements(images) after choosing a
     * two identical letters
     * @param {* clicked element} data
     * @param {* local styles of component} styles
     * @param {*} refCollection
     */




    function selectItem(data, refCollection) {

        const brickWrapper = data.target.parentElement;
        const bricksCollection = refCollection.current.childNodes;

        let selectedItems = 0;
        let selectedLettersArray = [];
        let sameElements = [];

        for (let b of Object.keys(bricksCollection)) {
            if (bricksCollection[b].className.includes('selected') === true) {
                selectedItems++;
            }
        }

        if (selectedItems < 2) {
            brickWrapper.classList.toggle(styles.selected);

        } else {
            brickWrapper.classList.remove(styles.selected);
        }

        for (let b of Object.keys(bricksCollection)) {
            if (bricksCollection[b].className.includes('selected') === true) {
                selectedLettersArray.push(bricksCollection[b].innerText);
                sameElements.push(bricksCollection[b])
            }
        }

        if ((selectedLettersArray.length === 2) && (selectedLettersArray[0] === selectedLettersArray[1])) {
            for (let el of sameElements) {
                el.parentNode.removeChild(el);
            }
        }


        if (bricksCollection.length === 11) {
            bricksCollection[0].classList.add(styles.dTransf);
            bricksCollection[7].classList.add(styles.eTransf);
            bricksCollection[2].classList.add(styles.rTransf)
        }

    }





    return (
        coordinatesArray.map((ar, i) => {

            items++;
            i = i + delay;
            if (lettersArr[items] === " ") {
                delay = delay - 1;
                return null
            } else {

                return changed.includes(i) ?

                    <div className={`${genStyles.brickWrapper} ${interval >= i ? genStyles.displayBrick : genStyles.undisplayBrick}`}
                        key={i}
                        style={{ transform: `translate(${ar[0]}px , ${ar[1]}px)` }}
                        onClick={selectedLetters ? colorClass === 'letterResult' ? (e) => handleResultClick(e) : (e) => selectItem(e, refCollection) : null}>
                        <p className={`${genStyles.letter} ${styles[colorClass]}`}>
                            {lettersArr[items]}
                        </p>
                        <img src={image} alt="brick" />
                    </div >

                    :

                    <div className={`${genStyles.brickWrapper} ${interval >= i ? genStyles.displayBrick : genStyles.undisplayBrick}`}
                        key={i}
                        style={{ transform: `translate(${ar[0]}px , ${ar[1]}px)` }}
                        onClick={selectedLetters ? (e) => selectItem(e, refCollection) : null}>
                        <p className={`${genStyles.letter} ${styles[colorClass]}`}>
                            {lettersArr[items]}
                        </p>
                        <img src={image} alt="brick" />
                    </div >
            }

        }
        )
    )
}






