import React from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


import { level } from './LevelFunctions/Level';

import './App.scss';

import Introduction from './components/Introduction/Introduction';
import Intro from './components/Intro/Intro';
import AboutComponent from './components/aboutComponent/AboutComponent';
import Menu from './components/menu/Menu';
import SkillsComponent from './components/skillsComponent/SkillsComponent';
import ContactComponent from './components/ContactComponent/ContactComponent';
import ProjectsComponent from './components/projectsComponent/ProjectsComponent';


function App() {


  const location = useLocation();
  const { pathname } = location;

  const lowLevelLetters = Array.from("DNWRREVELEZNBOP");
  const middleLevelLetters = Array.from("@NI MRI#Z#KNKV@")
  const topLevelLetters = Array.from(" M  OV A  A  O ");

  function coordinatesTable(cols, rows, colWidth, rowHeight, shift) {
    let coordinatesArray = new Array(0);

      for (let r = 0; r < rows; r++) {
        for (let i = 0; i < cols; i++) {
          coordinatesArray.push([i * colWidth + shift, r * rowHeight + shift])
        }
      }
    return coordinatesArray;
  }


      return (
        <div className="app">

          <Introduction
                        lowLevelLetters={lowLevelLetters}
                        middleLevelLetters={middleLevelLetters}
                        topLevelLetters={topLevelLetters}
                        coordinatesTable={coordinatesTable}

          />

          { pathname === '/' ? <Intro level={level}/> : null}

          <Menu level={level}/>


          <TransitionGroup>
            <CSSTransition key={location.key} classNames="slide" timeout={1000}>
              <Routes>

                  <Route path="omne" element={<AboutComponent level={level} />} exact>
                  </Route>

                  <Route path="skills" element={<SkillsComponent level={level} />} exact>
                  </Route>

                  <Route path="kontakt" element={<ContactComponent level={level} />} exact>
                  </Route>

                  <Route path="projekty" element={<ProjectsComponent />} exact>
                  </Route>

              </Routes>
            </CSSTransition>
          </TransitionGroup>



        </div>
      );



}


export default App;
