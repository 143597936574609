import React,{useState, useCallback, useEffect} from 'react';

import styles from './ContactComponent.module.scss';

import ContactForm from './ContactForm';

import { FaLinkedinIn } from 'react-icons/fa';
import { GrFacebookOption } from 'react-icons/gr';
import { IoLogoGithub } from 'react-icons/io';
//import { TRUE } from 'node-sass';



export default function ContactComponent(props) {


    const [ mobileContactForm, setMobileContactForm ] = useState(false);
    const [ focusedInputs, setFocusedInputs ] = useState({
        name: false,
        email: false,
        message: false
    })

    const focusContactForm = useCallback((e) => {
        let specificInput = e.target.name;

        setFocusedInputs({
            name: whichInputName(focusedInputs, 'name') === specificInput  ? whichEventOnInput(e, specificInput) : false,
            email: whichInputName(focusedInputs, 'email') === specificInput  ? whichEventOnInput(e, specificInput) : false,
            message: whichInputName(focusedInputs, 'message') === specificInput  ? whichEventOnInput(e, specificInput) : false
        })
    }, [])


    useEffect(() => {
        const { name, email, message} = focusedInputs;

        !name && !email && !message ? setMobileContactForm(false) : setMobileContactForm(true)
        return () => {
        }
    }, [focusedInputs])

    const whichEventOnInput = (event, inputName) => {
        if (event.type === 'focus' &&  inputName) {
            return true
        } else if (event.type === 'blur' && inputName) {
            return false
        }
    }

    const whichInputName = (obj, data) => {
        if (obj.hasOwnProperty(data)) {
            return data;
        } else {
            return null
        }
    }



    return (

        <div className={`${styles.contactContainer} ${mobileContactForm ? styles.mobileFocusWrapper : ''}`}>
            <div className={styles.contactForm}>

                <ContactForm focusContactForm={focusContactForm}/>

                <div className={styles.social}>
                    <div className={styles.contactWrapper}>
                            <a href="https://www.linkedin.com/in/miroslav-viazanko" target="blank">
                                <FaLinkedinIn  size={70} strokeWidth=".8" stroke="#525B56" fill="#525B56"/>
                            </a>

                    </div>
                    <div className={styles.contactWrapper}>
                            <a href="https://github.com/miroviazanko" target="blank">
                                <IoLogoGithub  size={70} strokeWidth=".8" stroke="#525B56" fill="#525B56"/>
                            </a>

                    </div>

                    <div className={styles.contactWrapper}>
                        <a href="https://github.com/miroviazanko" target="blank">
                            <GrFacebookOption size={70} height="100%" strokeWidth=".1" stroke="#525B56" fill="#525B56"/>
                        </a>

                    </div>
                </div>


            </div>
        </div>

    )
}
