import { useRef, useEffect, useState } from 'react';

import genStyles from '../GeneralStyles.module.scss';
import styles from './Introduction.module.scss';


import brick from '../../assets/images/brick-3d-sandstone-compr-95x115.png';



export default function Introduction(props) {

    const [counter, setCounter] = useState(0);


    const coordinatesTable =  props.coordinatesTable;
    const lowLevelLetters = props.lowLevelLetters;
    const middleLevelLetters = props.middleLevelLetters;
    const topLevelLetters = props.topLevelLetters;

    const bricksCollectionRef = useRef();


    useEffect(() => {
        const timer = setInterval(() => {
            counter > 90 ?
                clearInterval(timer)    :   setCounter(prevState => prevState + 1)
        }, 30);

        return () => clearInterval(timer);
    }, [counter]);



    const level = (cols, rows, colWidth, rowHeight, shift, letters, colorClass, count, ...changed) => {

        let coordinatesArray = coordinatesTable(cols, rows, colWidth, rowHeight, shift);
        let items = -1


        return (
            coordinatesArray.map((ar, i) => {

                            items++;
                            i = i + count;
                            if (letters[items] === " ") {
                                return null
                            } else {

                                return changed.includes(i) ?

                                    <div className={`${genStyles.brickWrapper} ${counter >= i ? genStyles.displayBrick : genStyles.undisplayBrick}`}
                                         key={i}
                                         style={{ left: `${ar[0]}px`, top: `${ar[1]}px` }}
                                         onClick={ colorClass === 'letterResult' ? (e) => handleResultClick(e) : (e) => selectItem(e)}>
                                                <p className={`${genStyles.letter} ${styles[colorClass]}`}>
                                                    {letters[items]}
                                                </p>
                                                <img src={brick} alt="brick" />
                                        </div >

                                        :

                                    <div className={`${genStyles.brickWrapper} ${counter >= i ? genStyles.displayBrick : genStyles.undisplayBrick}`}
                                         key={i}
                                         style={{ left: `${ar[0]}px`, top: `${ar[1]}px` }}
                                         onClick={(e) => selectItem(e)}>
                                                <p className={genStyles.letter}>
                                                    {letters[items]}
                                                </p>
                                                <img src={brick} alt="brick" />
                                        </div >
                                }

                }
            )
        )
    }


    const handleResultClick = (data) => {
        const brickWrapper = data.target.parentElement;

        brickWrapper.classList.toggle(styles.resultBlick);
        brickWrapper.addEventListener('animationend', () => {
            brickWrapper.classList.toggle(styles.resultBlick);
        });
    }


    const selectItem = (data) => {

        const brickWrapper = data.target.parentElement;
        const bricksCollection = bricksCollectionRef.current.childNodes;

        let selectedItems = 0;
        let selectedLettersArray = [];
        let sameElements = [];

        for (let b of Object.keys(bricksCollection)) {
            if (bricksCollection[b].className.includes('selected') === true) {
                selectedItems++;
            }
        }

        if (selectedItems < 2 ) {
            brickWrapper.classList.toggle(styles.selected);

        } else {
            brickWrapper.classList.remove(styles.selected);
        }

        for(let b of Object.keys(bricksCollection)) {
            if (bricksCollection[b].className.includes('selected') === true) {
                selectedLettersArray.push(bricksCollection[b].innerText);
                sameElements.push(bricksCollection[b])
            }
        }

        if( (selectedLettersArray.length === 2) && (selectedLettersArray[0] === selectedLettersArray[1])  ) {
            for (let el of sameElements) {
                el.parentNode.removeChild(el);
            }
        }


        if( bricksCollection.length === 11) {
            bricksCollection[0].classList.add(styles.dTransf);
            bricksCollection[7].classList.add(styles.eTransf);
            bricksCollection[2].classList.add(styles.rTransf)
        }

    }




    return (
        <div className={styles.introduction}
             ref={bricksCollectionRef}>

            {level(5, 3, 79, 98, 0, lowLevelLetters, 'letterResult',15, 15,17,19,20,21,22,23,24,27,28,29)}
            {level(5, 3, 79, 98, -16, middleLevelLetters, 'letterchanged1',30, 30, 37 ,39, 44 )}
            {level(5, 3, 79, 98, -32, topLevelLetters, '', 45,)}

        </div>
    )
}
