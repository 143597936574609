import React, { useState, useEffect, useRef } from 'react';

import genStyles from '../../GeneralStyles.module.scss';
import styles from './About.module.scss'

import brick from '../../../assets/images/brick-3d-sandstone-compr-95x115.png';

import {levelObject, levelObjectActive} from './LevelPropertieObj';



export default function About(props) {

    const level = props.level;
    const activeRoute = props.activeRoute;

    const [counter, setCounter] = useState(0);
    const [properties, setProperties] = useState(levelObject);

    const bricksCollectionRef = useRef();

    const letters = Object.values(levelObject).map((m, i) => {
        return m.letters.length * i
    }).reduce((a, b) => a + b);

    // Set width, height of container element
    const { cols, rows } = properties[0];
    let containerSize = {
        width: `${(cols * 79) + 48}px`, height: `${(rows * 98) + 54}px`
    }

    const sorted = properties.map(m => {
        return (
            level(m, counter, genStyles, styles, brick, bricksCollectionRef)
        )
    })

    const handleActive = () => {
        activeRoute ? setProperties(properties.map((p, i) => {
            let { colorClass, letters, cols, rows } = levelObjectActive[i];
            return { ...p, colorClass, letters, cols, rows }
        })) : setProperties(properties.map((p, i) => {
            let { colorClass, letters, cols, rows } = levelObject[i]
            return { ...p, colorClass, letters, cols, rows }
        }))
    }


    useEffect(() => {
        handleActive();
        // eslint-disable-next-line
    }, [activeRoute])


    useEffect(() => {
        const timer = setInterval(() => {
            counter > (letters * 3) ?
                clearInterval(timer) : setCounter(prevState => prevState + 1)
        }, 50);

        return () => clearInterval(timer);
    }, [counter, letters]);




    return (

        <div className={styles.aboutContainer}
             style={{...containerSize}}
             ref={bricksCollectionRef}>

            {sorted}

        </div>

    )


}
