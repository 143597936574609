export const levelObjectActive = [
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("S  K IL L  S"),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 72,
        changed: [2, 4, 6],
    },
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("S  K IL L   "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 82,
        changed: [2, 4, 6],
    },
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("S     L     "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 92,
        changed: [2, 4, 6],
    }
]


export const levelObject = [
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("SKILLS      "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 51,
        changed: [2, 4, 6],
    },
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("SKILL       "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 55,
        changed: [2, 4, 6],
    },
    {
        cols: 2,
        rows: 6,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("S  L        "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 60,
        changed: [2, 4, 6],
    }
]
