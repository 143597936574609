import React, {useState, useEffect} from 'react';

import { Transition } from 'react-transition-group';
import ReactVivus from 'react-vivus';

import styles from './AboutComponent.module.scss';

import ball from '../../assets/images/svg/ball.svg'
import book from '../../assets/images/svg/book.svg'
import heart from '../../assets/images/svg/heart.svg'
import chat from '../../assets/images/svg/chat.svg'




export default function AboutComponent(props) {

    const svgs = [
        {
            id: "ball",
            file: ball,
            type: "one",
            duration: 400,
            text: "Mám rád šport. Naučil ma, že vstať po prehratom zápase je skutočným víťazstvom."
        },
        {
            id: "book",
            file: book,
            type: "",
            duration: 200,
            text: "Knihy sú mojou inšpiráciou. Najväčšou bola kniha Vojtecha Zamarovského o Heinrichovi Schliemannovi a jeho objavení Tróje."
        },
        {
            id: "heart",
            file: heart,
            type: "",
            duration: 200,
            text: "Boh a rodina sú mojimi najvzácnejšími darmi"
        },
        {
            id: "chat",
            file: chat,
            type: "",
            duration: 100,
            text: "Hovorím tromi svetovými jazykmi - anglicky, nemecky a rusky"
        },
    ]


    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(true)
    }, [])

    const duration = 550;

    const transitionStyles= {
        entering: { opacity: 0, transform: `scale(1.05)` },
        entered: { opacity: 1, transform: `scale(1)` },
        exiting: { opacity: 1, transform: `scale(1)` },
        exited: { opacity: 0, transform: `scale(1.05)` },
    };

    const paragraph = (text, transitions, delay) => {
        return (
            <Transition in={inProp} timeout={duration}>
                {state => (
                    <p style={{
                        transition: `all ${duration}ms ease-in-out ${delay}ms`,
                        opacity: 1,
                        ...transitions[state]
                    }}>
                        {text}

                    </p>
                )}
            </Transition>
        )
    }


    const renderSvgs = svgs.map((sv, i) => {
        return(
            <div className={styles.svgWrapper}
                 key={i}>
                <ReactVivus
                    id={sv.id}
                    option={{
                        file: sv.file,
                        type: 'delayed',
                        animTimingFunction: 'EASE',
                        duration: sv.duration,
                        onReady: null
                    }}
                    style={{ height: '100%', width: '100%' }}
                    callback={null}
                />
                {paragraph(sv.text, transitionStyles, 3000)}
            </div>
        )
    })


    return (

        <div className={styles.mainContainer}>

            { renderSvgs }

        </div>

    )
}
