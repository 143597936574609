import React, { useState, useEffect } from 'react';

import styles from './ProjectsComponent.module.scss';

import DetailProject from './DetailProject';

import project1 from '../../assets/images/projects/screenshot-muzeum-compr.jpg';
import project2 from '../../assets/images/projects/screenshot-szkt-compr.jpg';
import project3 from '../../assets/images/projects/screenshot-skolka-compr.jpg';
import project4 from '../../assets/images/projects/screenshot-nadmerka.jpg';




export default function ProjectsComponent(props) {

    const [gray, setGray] = useState(null);
    const [detailIndex, setDetailIndex ] = useState(null);
    const [detail, setDetail] = useState(false);
    const [detailImage, setDetailImage ] = useState();


    const projectsList = [[project1, "wordpress"], [project2, "html+css+bootstrap"], [project3, "react+sass+wordpress"], [project4, "wordpress+woocommerce"]];

    const projectsListImg = [project1, project2, project3, project4];

    useEffect(() => {
        return () => {
            setGray(null);
        }
    }, [])

    const handleClick = (e,i) => {
        if( e.target === e.currentTarget || e.key === 'Escape') {
            setDetail(prevState => !prevState);
            setDetailIndex(i);
            if ( !isNaN(i))  {
                setDetailImage(projectsListImg[i]);
            }
        }
    }



    const projects = projectsList.map((pr, i) => {
        return (
            <figure key={i}>
                <img src={pr[0]} alt="projekt"
                    onMouseEnter={(e) => setGray(i)}
                    onMouseLeave={(e) => setGray(null)}
                    onClick={(e) => handleClick(e,i)}
                    className={`${gray === null ? styles.setColor : ''} ${gray !== null && gray !== i ? styles.setGray : ''}`} />
                <figcaption>{pr[1]}</figcaption>
            </figure>
        )
    })



    return (

        <div className={styles.mainContainer}>

            {projects}

            { detail ?
                <DetailProject handleClick={handleClick}
                               detailImage={detailImage}
                               projectsListImg={projectsListImg}
                               arrayImgIndex={detailIndex}/>
                : null}


        </div>

    )
}
