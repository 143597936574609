import React from 'react'
import { Link, useLocation } from 'react-router-dom';

import styles from './Menu.module.scss';

import About from './about/About';
import Skills from './skills/Skills';
import Contact from './contact/Contact';
import Projects from './projects/Projects';



export default function Menu(props) {

    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/");

    return (
        <div className={styles.menuContainer}>

            <Link to="/omne">
                <About level={props.level}
                       activeRoute={splitLocation[1] === 'omne'}/>
            </Link>

            <Link to="/skills">
                <Skills level={props.level}
                        activeRoute={splitLocation[1] === 'skills'}/>
            </Link>

            <Link to="/kontakt">
                <Contact level={props.level}
                         activeRoute={splitLocation[1] === 'kontakt'}/>
            </Link>

            <Link to="/projekty">
                <Projects level={props.level}
                         activeRoute={splitLocation[1] === 'projekty'}/>
            </Link>

        </div>
    )
}
