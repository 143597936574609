export const levelObjectActive = [
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("KO       NTA       KT"),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 42,
        changed: [2, 4, 6],
    },
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("KO       NTA       K "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 52,
        changed: [2, 4, 6],
    },
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("KO       N           "),
        colorClass: 'selected',
        selectedLetters: false,
        delay: 62,
        changed: [2, 4, 6],
    }
]


export const levelObject = [
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 32,
        letters: Array.from("       KONTAKT       "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 61,
        changed: [2, 4, 6],
    },
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 16,
        letters: Array.from("       KONTAK        "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 68,
        changed: [2, 4, 6],
    },
    {
        cols: 7,
        rows: 3,
        colWidth: 79,
        rowHeight: 98,
        shift: 0,
        letters: Array.from("       KON           "),
        colorClass: 'letterchanged1',
        selectedLetters: false,
        delay: 74,
        changed: [2, 4, 6],
    }
]
