import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

import styles from './Intro.module.scss';

import photo from '../../assets/images/m/me-transp2-shadow-compr.png';



export default function AboutComponent(props) {

    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(true)
    }, [])

    const duration = 350;

    const transitionStyles = {
        entering: { transform: `translateY(-10%)`, opacity: 0 },
        entered: { transform: `translateY(0)`, opacity: 1 },
        exiting: { transform: `translateY(0)`, opacity: 1 },
        exited: { transform: `translateY(-10%)`, opacity: 0 },
    };

    const transitionStylesPhoto = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 1 },
        exited: { opacity: 0 },
    };

    const text1 = "Som web developer."
    const text2 = `Špecializujem sa prevažne na Wordpress, ale mám skúsenosti aj s Reactom.`;
    const text3 = `Programovanie je o výzvach, riešení problémov a neustálom vzdelávaní a táto pestrosť je to čo ma baví...`
    const text4 = `Výsledkom je hotový produkt - webstránka a predovšetkým vaša spokojnosť.`

    const paragraph = (text, transitions, delay) => {
        return (
            <Transition in={inProp} timeout={duration}>
                {state => (
                    <p style={{
                        transition: `all ${duration}ms ease-in-out ${delay}ms`,
                        opacity: 1,
                        ...transitions[state]
                    }}>

                        {text}

                    </p>
                )}
            </Transition>
        )
    }

    const photoTrans = (transitions, delay) => {
        return (
            <Transition in={inProp} timeout={duration}>
                {state => (
                    <img src={photo} alt="fotka"
                        style={{
                            transition: `all ${duration}ms ease-in-out ${delay}ms`,
                            opacity: 1,
                            ...transitions[state]
                        }} />


                )}
            </Transition>
        )
    }



    return (

        <div className={styles.mainContainer}>
            <div>
                {paragraph(text1, transitionStyles, 3400)}
                {paragraph(text2, transitionStyles, 3600)}
                {paragraph(text3, transitionStyles, 3800)}
                {paragraph(text4, transitionStyles, 4000)}
            </div>

            {photoTrans(transitionStylesPhoto, 4000)}

        </div>

    )
}
